import api from "../providers/api";

export function authenticateInAPI({ email, password }) {
  return api.post("/auth/authenticate", {
    username: email,
    password
  });
}

export function registerInAPI({ name, email, password, username }) {
  return api.post("/auth/register", {
    name,
    email,
    password,
    username
  });
}
