import { takeLatest, put, call } from "redux-saga/effects";
import { authenticateInAPI } from "../../../services/auth";
import navigateTo from "../../../services/navigation";
import { setAuthInfo } from "../../../helpers/auth";

import { toastr } from "react-redux-toastr";

function* authenticate(action) {
  yield put({ type: "AUTHENTICATE_PENDING" });

  try {
    const authResult = yield call(authenticateInAPI, action.payload);

    if (authResult.status === 200) {
      if (authResult.data.user.active) {
        yield put({ type: "AUTHENTICATE_SUCCESS", payload: authResult.data });
        setAuthInfo(authResult.data);
        navigateTo("/");
      } else {
        yield put({ type: "AUTHENTICATE_FAILURE" });
        toastr.error("", "Você ainda não confirmou seu endereço de email");
      }
    } else {
      yield put({ type: "AUTHENTICATE_FAILURE" });
      toastr.error("", "Usuário/email ou senha inválidos.");
    }
  } catch (error) {
    yield put({ type: "AUTHENTICATE_FAILURE" });
    toastr.error("", "Usuário/email ou senha inválidos.");
  }
}

export default function* watchAuthenticate() {
  yield takeLatest("AUTHENTICATE", authenticate);
}
