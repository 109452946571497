import React, { useState, useEffect } from "react";

import { logoutUser } from "../../helpers/auth";
import { history } from "../../helpers/routing";
import { getGravatarUrl } from "../../helpers/avatar";

import { useSelector } from "react-redux";

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const authData = useSelector(state => state.auth.authData);

  useEffect(() => {
    if (authData) {
      setUserName(authData.user.name);
      setUserEmail(authData.user.email);
    }
  }, [authData]);

  return (
    <>
      <div className="header py-4">
        <div className="container">
          <div className="d-flex">
            <a className="header-brand" href="https://tcc.mvsouza.com.br">
              <img
                src={require("../../assets/images/tabler.svg")}
                className="header-brand-img"
                alt="tabler logo"
              />
            </a>
            <div className="d-flex order-lg-2 ml-auto">
              <div className="dropdown">
                <a
                  href="teste"
                  className="nav-link pr-0 leading-none"
                  data-toggle="dropdown"
                  onClick={e => {
                    e.preventDefault();
                    setOpenMenu(!openMenu);
                  }}
                >
                  <span className="avatar">
                    <img
                      src={getGravatarUrl(userEmail)}
                      alt="Imagem de perfil"
                    />
                  </span>
                  <span className="ml-2 d-none d-lg-block">
                    <span className="text-default">{userName}</span>
                    <small className="text-muted d-block mt-1 dropdown-toggle">
                      Gerenciar
                    </small>
                  </span>
                </a>
                <div
                  style={{ display: openMenu ? "block" : "none" }}
                  className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                >
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/profile")}
                  >
                    <i className="dropdown-icon fe fe-user"></i> Perfil
                  </button>
                  {/*<button className="dropdown-item">
                    <i className="dropdown-icon fe fe-settings"></i>{" "}
                    Configurações
                    </button>*/}
                  <div className="dropdown-divider"></div>
                  {/*<button className="dropdown-item">
                    <i className="dropdown-icon fe fe-help-circle"></i> Ajuda
                  </button>*/}
                  <button
                    className="dropdown-item"
                    onClick={() => logoutUser(history)}
                  >
                    <i className="dropdown-icon fe fe-log-out"></i> Sair
                  </button>
                </div>
              </div>
            </div>
            <a
              href="asd"
              className="header-toggler d-lg-none ml-3 ml-lg-0"
              data-toggle="collapse"
              data-target="#headerMenuCollapse"
            >
              <span className="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="header collapse d-lg-flex p-0" id="headerMenuCollapse">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg order-lg-first">
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                {/*<li className="nav-item">
                  <button
                    onClick={() => history.replace("/")}
                    className="nav-link"
                  >
                    <i className="fe fe-home"></i> Home
                  </button>
                </li>*/}
                <li className="nav-item">
                  <button
                    onClick={() => history.replace("/")}
                    className="nav-link active"
                  >
                    <i className="fe fe-box"></i> Projetos
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
