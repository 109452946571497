import React, { useEffect } from "react";
import { history } from "../../helpers/routing";
import api from "../../providers/api";
import { toastr } from "react-redux-toastr";

export default function EmailConfirmation({ match }) {
  useEffect(() => {
    api
      .post("/auth/email_confirmation", {
        token: match.params.token
      })
      .then(response => {
        if (response.status === 200) {
          toastr.success(
            "",
            "Email confirmado com sucesso! Faça login para continuar."
          );
        } else {
          toastr.error("", "Erro ao confirmar email.");
        }
      })
      .then(() => history.replace("/login"));
  }, [match.params.token]);

  return <></>;
}
