import React, { useState, useEffect } from "react";
import Wrapper from "../../components/Wrapper";

import api from "../../providers/api";

import Moment from "react-moment";

import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/CodeBlock";
import BackButton from "../../components/BackButton";
import DropdownButton from "../../components/DropdownButton";

import { history } from "../../helpers/routing";

import "./styles.css";
import { toastr } from "react-redux-toastr";

export default function ProjectDetails({ match }) {
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("description");

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/project/${match.params.slug}`)
      .then(res => {
        setIsLoading(false);
        setProject(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [match.params.slug]);

  const handleDelete = () => {
    const confirmOptions = {
      onOk: async () => {
        setIsLoading(true);

        const response = await api.delete(`/project/${match.params.slug}`);
        setIsLoading(false);

        if (response.status === 200) {
          toastr.success("", "Projeto excluído com sucesso!");
          history.replace("/");
        } else {
          toastr.error("", "Erro ao excluir projeto.");
        }
      },
      onCancel: () => {}
    };

    toastr.confirm(
      "Tem certeza que deseja excluir este projeto?",
      confirmOptions
    );
  };

  return (
    <Wrapper>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row row-cards row-deck">
            <div className="col-12">
              <div className="card">
                <div className={"dimmer" + (isLoading ? " active" : "")}>
                  <div className="loader"></div>
                  <div className="dimmer-content">
                    <div className="card-header">
                      <div className="d-flex">
                        <BackButton />
                        <h3 className="card-title">{project.title}</h3>
                      </div>
                      <span className="ml-auto">
                        <DropdownButton
                          buttonIcon="settings"
                          items={[
                            {
                              label: "Publicar Versão",
                              icon: "plus",
                              onPress: () =>
                                history.push(
                                  `/project/${match.params.slug}/publish`
                                )
                            },
                            { divider: true },
                            {
                              label: "Editar",
                              icon: "edit",
                              onPress: () =>
                                history.push(
                                  `/project/${match.params.slug}/edit`
                                )
                            },
                            {
                              label: "Excluir",
                              icon: "trash",
                              onPress: handleDelete
                            }
                          ]}
                        />
                      </span>
                    </div>
                    <div className="menu">
                      <ul>
                        <li
                          onClick={() => setSelectedTab("description")}
                          className={
                            selectedTab === "description" ? "menu-selected" : ""
                          }
                        >
                          Descrição
                        </li>
                        <li
                          onClick={() => setSelectedTab("versions")}
                          className={
                            selectedTab === "versions" ? "menu-selected" : ""
                          }
                        >
                          Versões
                        </li>
                        {/*<li
                          onClick={() => setSelectedTab("stats")}
                          className={
                            selectedTab === "stats" ? "menu-selected" : ""
                          }
                        >
                          Estatísticas
                        </li>*/}
                      </ul>
                    </div>
                    {selectedTab === "versions" && (
                      <div
                        className="table-responsive"
                        style={{ minHeight: "150px" }}
                      >
                        {!isLoading && project.versions.length === 0 && (
                          <span
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "64px"
                            }}
                          >
                            Você ainda não publicou nenhuma versão.
                          </span>
                        )}

                        {!isLoading && project.versions.length !== 0 && (
                          <table className="table card-table table-vcenter text-nowrap">
                            <thead>
                              <tr>
                                <th>Título</th>
                                <th>Data de Publicação</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {project.versions.map(v => (
                                <tr key={v.id}>
                                  <td>{v.title}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {v.createdAt}
                                    </Moment>
                                  </td>

                                  <td className="text-right">
                                    <button
                                      onClick={() =>
                                        history.push(
                                          `/project/${match.params.slug}/${v.title}`
                                        )
                                      }
                                      type="button"
                                      className="btn btn-sm btn-secondary"
                                    >
                                      <i className="fe fe-plus mr-2"></i>
                                      Detalhes
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}

                    {(selectedTab === "description" ||
                      selectedTab === "stats") && (
                      <div className="card-body" style={{ minHeight: "150px" }}>
                        {!isLoading && !project.title && (
                          <span
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "44px"
                            }}
                          >
                            Projeto não encontrado
                          </span>
                        )}

                        {selectedTab === "description" &&
                          project.description && (
                            <ReactMarkdown
                              renderers={{ code: CodeBlock }}
                              className="description"
                              source={project.description}
                            />
                          )}

                        {selectedTab === "stats" && <span>Estatísticas</span>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
