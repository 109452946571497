import { get, set, remove } from "../helpers/storage";
import { history } from "../helpers/routing";

export const isAuthenticated = () => {
  return get("auth") !== null;
};

export const validateJWTExistingToken = async () =>
  new Promise((resolve, reject) => {
    if (isAuthenticated()) {
      resolve(true);
      /*api
        .post("/auth/refresh_token")
        .then(response => {
          if (response.status === 200) {
            setAuthInfo(response.data);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          remove("auth");
          resolve(false);
        });*/
    } else {
      resolve(false);
    }
  });

export const getToken = () => {
  const authData = get("auth");
  return authData !== null ? authData.token : null;
};

export const getAuthInfo = () => {
  return get("auth");
};

export const setAuthInfo = info => {
  set("auth", info);
};

export const logoutUser = () => {
  remove("auth");
  history.replace("/login");
};
