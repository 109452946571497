import React from "react";

import Wrapper from "../../components/Wrapper";
import { history } from "../../helpers/routing";

export default function NotFoundPage() {
  return (
    <Wrapper hideHeader hideFooter pageContent>
      <div className="container text-center">
        <div className="display-1 text-muted mb-5">
          <i className="si si-exclamation"></i> 404
        </div>
        <h1 className="h2 mb-3">
          Oops.. Não conseguimos encontrar o que você está procurando
        </h1>

        <button className="btn btn-primary" onClick={() => history.goBack()}>
          <i className="fe fe-arrow-left mr-2"></i>Voltar
        </button>
      </div>
    </Wrapper>
  );
}
