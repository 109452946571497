import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";

import brand from "../../assets/images/tabler.svg";
import { history } from "../../helpers/routing";

import api from "../../providers/api";

import { toastr } from "react-redux-toastr";

export default function ResetPassword({ match }) {
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const validationErrors = {};

    validationErrors.passwordMissing = !password;
    validationErrors.passwordConfirmationMissing = !passwordConfirmation;
    validationErrors.passwordConfirmationInvalid =
      password && passwordConfirmation && password !== passwordConfirmation;

    setErrors(validationErrors);

    return (
      !validationErrors.passwordMissing &&
      !validationErrors.passwordConfirmationMissing &&
      !validationErrors.passwordConfirmationInvalid
    );
  };

  const handleResetPassword = async e => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const response = await api.post("/auth/create_new_password", {
        token: match.params.token,
        password,
        passwordConfirmation
      });

      setLoading(false);

      if (response.status === 200) {
        toastr.success("", "Senha resetada com sucesso!");
        history.replace("/login");
      } else {
        toastr.error("", "Erro ao resetar senha.");
      }
    }
  };

  return (
    <div className="page-single">
      <div className="container">
        <div className="row">
          <div className="col col-login mx-auto">
            <div className="text-center mb-6">
              <img src={brand} className="h-6" alt="" />
            </div>
            <form
              className="card"
              autoComplete="off"
              onSubmit={handleResetPassword}
            >
              <div className="card-body p-6">
                <div className="card-title">Crie uma nova senha</div>

                <div className="form-group">
                  <label htmlFor="txtSenha" className="form-label">
                    Nova Senha
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errors.passwordMissing ||
                      errors.passwordConfirmationInvalid
                        ? " is-invalid"
                        : "")
                    }
                    id="txtSenha"
                    placeholder="Senha"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    disabled={isLoading}
                  />
                  {errors.passwordMissing && (
                    <div className="invalid-feedback">Informe a nova senha</div>
                  )}
                  {errors.passwordConfirmationInvalid && (
                    <div className="invalid-feedback">
                      As senhas não são iguais
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="txtConfirmacaoSenha" className="form-label">
                    Repita a nova senha
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errors.passwordConfirmationMissing ||
                      errors.passwordConfirmationInvalid
                        ? " is-invalid"
                        : "")
                    }
                    id="txtConfirmacaoSenha"
                    placeholder="Confirmação de senha"
                    onChange={e => {
                      setPasswordConfirmation(e.target.value);
                    }}
                    value={passwordConfirmation}
                    disabled={isLoading}
                  />
                  {errors.passwordConfirmationMissing && (
                    <div className="invalid-feedback">
                      Informe novamente sua nova senha
                    </div>
                  )}
                  {errors.passwordConfirmationInvalid && (
                    <div className="invalid-feedback">
                      As senhas não são iguais
                    </div>
                  )}
                </div>

                <div className="form-footer">
                  <button type="submit" className="btn btn-primary btn-block">
                    {isLoading ? (
                      <FontAwesomeIcon icon={loadingIcon} spin />
                    ) : (
                      "Criar nova senha"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-muted">
              Já possui uma conta?{" "}
              <button
                type="button"
                className="custom-link"
                onClick={() => history.replace("/login")}
              >
                Faça login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
