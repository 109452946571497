import api from "../providers/api";

export const validateEmail = email => {
  var regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gim;

  return regex.test(String(email).toLowerCase());
};

export const validatePasswordStrength = password => {
  const errors = [];

  if (password.length === 0) {
    errors.push("Informe a senha");
  }

  const rules = ["[A-Z]", "[a-z]", "[0-9]"];

  if (
    (!new RegExp(rules[0]).test(password) &&
      !new RegExp(rules[1]).test(password)) ||
    !new RegExp(rules[2]).test(password)
  ) {
    errors.push("A senha deve conter letras e números");
  }

  return { errors, valid: errors.length === 0 };
};

export const existsUsername = async username => {
  const response = await api.get(`/auth/validate_username/${username}`);
  return response.status !== 200;
};

export const existsEmail = async email => {
  const response = await api.get(`/auth/validate_email/${email}`);
  return response.status !== 200;
};
