import { takeLatest, put, call } from "redux-saga/effects";
import { updateProjectInAPI } from "../../../services/projects";
import navigateTo from "../../../services/navigation";

function* updateProject(action) {
  yield put({ type: "UPDATE_PROJEC_PENDING" });

  try {
    const updatedProject = yield call(updateProjectInAPI, action.payload);
    yield put({ type: "UPDATE_PROJEC_SUCCESS", payload: updatedProject });
    navigateTo("/admin/projects");
  } catch (error) {
    yield put({ type: "UPDATE_PROJEC_FAILURE" });
  }
}

export default function* watchUpdateProject() {
  yield takeLatest("UPDATE_PROJEC", updateProject);
}
