import { all } from "redux-saga/effects";
import watchFetchProjects from "./projects/fetch";
import watchDeleteProject from "./projects/delete";
import watchCreateProject from "./projects/create";
import watchUpdateProject from "./projects/update";
import watchAuthenticate from "./auth/login";
import watchRegister from "./auth/register";

export default function* rootSaga() {
  yield all([
    watchFetchProjects(),
    watchDeleteProject(),
    watchCreateProject(),
    watchUpdateProject(),
    watchAuthenticate(),
    watchRegister()
  ]);
}
