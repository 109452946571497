import React, { useState, useEffect } from "react";
import fileDownload from "js-file-download";

import Wrapper from "../../components/Wrapper";

import api from "../../providers/api";

import Moment from "react-moment";

import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/CodeBlock";

import BackButton from "../../components/BackButton";
import DropdownButton from "../../components/DropdownButton";
import Modal from "../../components/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import { toastr } from "react-redux-toastr";

export default function VersionDetails({ history, match }) {
  const [version, setVersion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("notes");
  const [visible, setVisible] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/version/${match.params.slug}/${match.params.version}`)
      .then(res => {
        setIsLoading(false);
        setVersion(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [match.params.slug, match.params.version]);

  const downloadFile = (url, title) => {
    api
      .get(url, {
        responseType: "blob"
      })
      .then(response => {
        fileDownload(response.data, title);
      });
  };

  const validateFormUpload = () => {
    const validationErrors = {};

    validationErrors.fileMissing = !file;

    setErrors(validationErrors);

    return !validationErrors.fileMissing;
  };

  const uploadFile = async e => {
    e.preventDefault();

    if (!validateFormUpload()) return;

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    const response = await api.post(
      `/version/${match.params.slug}/${match.params.version}/files`,
      formData,
      config
    );

    setUploading(false);
    setFile(null);

    if (response.status === 201) {
      setVersion(response.data.data);
      setSelectedTab("files");
      setVisible(false);
      toastr.success("", "Arquivo enviado com sucesso!");
    } else {
      toastr.error("", "Erro ao enviar arquivo.");
    }
  };

  const deleteVersion = async () => {
    const confirmOptions = {
      onOk: async () => {
        const response = await api.delete(
          `/version/${match.params.slug}/${match.params.version}`
        );

        if (response.status === 200) {
          toastr.success("", "Versão excluída com sucesso!");
          history.replace(`/project/${match.params.slug}`);
        } else {
          toastr.error("", "Erro ao excluir versão.");
        }
      },
      onCancel: () => {}
    };

    toastr.confirm(
      "Tem certeza que deseja excluir esta versão?",
      confirmOptions
    );
  };

  const deleteUpload = async file => {
    const confirmOptions = {
      onOk: async () => {
        const response = await api.delete(`/upload/${file.id}`);

        if (response.status === 200) {
          const newVersion = { ...version };
          newVersion.files = version.files.filter(f => f.id !== file.id);
          setVersion(newVersion);

          toastr.success("", "Arquivo excluído com sucesso!");
        } else {
          toastr.error("", "Erro ao excluir arquivo.");
        }
      },
      onCancel: () => {}
    };

    toastr.confirm(
      "Tem certeza que deseja excluir este arquivo?",
      confirmOptions
    );
  };

  return (
    <Wrapper>
      <Modal
        closeOnClickOutside
        visible={visible}
        setVisible={setVisible}
        title="Enviar Arquivo"
        isLoading={isUploading}
      >
        <form onSubmit={uploadFile}>
          <div className="form-group">
            <div className="form-label">Selecione o arquivo para enviar</div>
            <div className="custom-file">
              <input
                type="file"
                onChange={e => setFile(e.target.files[0])}
                className={
                  "custom-file-input" +
                  (errors.fileMissing ? " is-invalid" : "")
                }
              />
              {errors.fileMissing && (
                <div className="invalid-feedback">
                  Selecione um arquivo para enviar
                </div>
              )}
              <label className="custom-file-label">
                {file ? file.name : "Escolher arquivo"}
              </label>
            </div>
          </div>

          <div className="text-right">
            <button
              type="submit"
              className="btn btn-dark ml-auto"
              disabled={isUploading}
            >
              {isUploading ? (
                <FontAwesomeIcon icon={loadingIcon} spin />
              ) : (
                "Enviar Arquivo"
              )}
            </button>
          </div>
        </form>
      </Modal>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row row-cards row-deck">
            <div className="col-12">
              <div className="card">
                <div className={"dimmer" + (isLoading ? " active" : "")}>
                  <div className="loader"></div>
                  <div className="dimmer-content">
                    <div className="card-header">
                      <div className="d-flex">
                        <BackButton />
                        <h3 className="card-title">{version.title}</h3>
                      </div>

                      <span className="ml-auto">
                        <DropdownButton
                          buttonIcon="settings"
                          items={[
                            {
                              label: "Enviar Arquivo",
                              icon: "file-plus",
                              onPress: () => setVisible(true)
                            },
                            {
                              divider: true
                            },
                            {
                              label: "Editar",
                              icon: "edit",
                              onPress: () =>
                                history.push(
                                  `/project/${match.params.slug}/${match.params.version}/edit`
                                )
                            },
                            {
                              label: "Excluir",
                              icon: "trash",
                              onPress: deleteVersion
                            }
                          ]}
                        />
                      </span>
                    </div>
                    <div className="menu">
                      <ul>
                        <li
                          onClick={() => setSelectedTab("notes")}
                          className={
                            selectedTab === "notes" ? "menu-selected" : ""
                          }
                        >
                          Notas da Versão
                        </li>
                        <li
                          onClick={() => setSelectedTab("files")}
                          className={
                            selectedTab === "files" ? "menu-selected" : ""
                          }
                        >
                          Arquivos
                        </li>
                      </ul>
                    </div>
                    {selectedTab === "files" && (
                      <div
                        className="table-responsive"
                        style={{ minHeight: "150px" }}
                      >
                        {!isLoading && version.files.length === 0 && (
                          <span
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "64px"
                            }}
                          >
                            Você ainda não enviou arquivos para esta versão.
                          </span>
                        )}

                        {!isLoading && version.files.length !== 0 && (
                          <table className="table card-table table-vcenter text-nowrap">
                            <thead>
                              <tr>
                                <th>Título</th>
                                <th>Data de Envio</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {version.files.map(file => (
                                <tr key={file.id}>
                                  <td>{file.title}</td>
                                  <td>
                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                      {file.createdAt}
                                    </Moment>
                                  </td>

                                  <td className="text-right">
                                    <button
                                      onClick={() => deleteUpload(file)}
                                      type="button"
                                      className="btn btn-sm btn-danger mr-1"
                                    >
                                      <i className="fe fe-trash"></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        downloadFile(
                                          `/version/download/${file.id}`,
                                          file.title
                                        )
                                      }
                                      type="button"
                                      className="btn btn-sm btn-secondary"
                                    >
                                      <i className="fe fe-download mr-2"></i>
                                      Baixar
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}

                    {selectedTab === "notes" && (
                      <div className="card-body" style={{ minHeight: "150px" }}>
                        {!isLoading && !version.title && (
                          <span
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "44px"
                            }}
                          >
                            Projeto não encontrado
                          </span>
                        )}

                        {selectedTab === "notes" && version.changelog && (
                          <ReactMarkdown
                            renderers={{ code: CodeBlock }}
                            className="description"
                            source={version.changelog}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
