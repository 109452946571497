import React, { useState, useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";

import { history } from "../../helpers/routing";

import Modal from "../../components/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";

import api from "../../providers/api";
import { toastr } from "react-redux-toastr";

export default function Projects() {
  const projects = useSelector(state => state.projects.items);
  const isLoading = useSelector(state => state.projects.loading);

  const [visible, setVisible] = useState(false);
  const [isGeneratingKey, setGeneratingKey] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showHelp, setShowHelp] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "FETCH_PROJECTS" });
  }, [dispatch]);

  const integrationModal = project => {
    setSelectedProject(project);
    setVisible(true);
  };

  const handleGenerateKey = e => {
    e.preventDefault();

    if (selectedProject) {
      const confirmOptions = {
        onOk: async () => {
          setGeneratingKey(true);
          const response = await api.post(
            `/project/${selectedProject.slug}/generateapikey`
          );

          if (response.status === 200) {
            setSelectedProject(response.data.data);
            toastr.success("", "Chave de API gerada com sucesso!");
          } else {
            toastr.error("", "Erro ao gerar Chave de API.");
          }

          setGeneratingKey(false);
        },
        onCancel: () => {}
      };

      toastr.confirm(
        "Tem certeza que deseja gerar uma nova Chave de API? (Gerar uma nova chave, inutilizará a chave existente)",
        confirmOptions
      );
    }
  };

  return (
    <Wrapper>
      <Modal
        closeOnClickOutside
        visible={visible}
        setVisible={setVisible}
        title="Integrações"
        isLoading={isGeneratingKey}
        onClose={() => dispatch({ type: "FETCH_PROJECTS" })}
      >
        <form onSubmit={handleGenerateKey}>
          <div className="form-group">
            <label htmlFor="txtApiKey" className="form-label">
              Chave de Integração
            </label>
            <div className="row gutters-sm">
              <div className="col">
                <input
                  id="txtApiKey"
                  type="text"
                  className="form-control"
                  value={(selectedProject && selectedProject.apiKey) || ""}
                  readOnly
                />
              </div>
              <span
                onClick={() => setShowHelp(!showHelp)}
                className="col-auto align-self-center"
              >
                <span className="form-help" data-placement="top">
                  ?
                </span>
              </span>
            </div>

            {showHelp && (
              <div
                className="popover fade bs-popover-top show"
                role="tooltip"
                style={{
                  maxWidth: "350px",
                  right: "-5px",
                  top: "-20px",
                  left: "unset"
                }}
              >
                <div className="arrow" style={{ right: "5px" }}></div>
                <div className="popover-body">
                  Gerar uma nova chave inutilizará a chave antiga.
                </div>
              </div>
            )}
          </div>

          <div className="text-right">
            <button
              type="submit"
              className="btn btn-dark ml-auto"
              disabled={isGeneratingKey}
            >
              {isGeneratingKey ? (
                <FontAwesomeIcon icon={loadingIcon} spin />
              ) : (
                `Gerar${
                  selectedProject && selectedProject.apiKey ? " nova" : ""
                } chave de integração`
              )}
            </button>
          </div>
        </form>
      </Modal>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row row-cards row-deck">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Projetos</h3>
                  <span className="ml-auto">
                    <button
                      onClick={() => history.push("/project/new")}
                      className="btn btn-sm btn-dark"
                    >
                      Criar projeto
                    </button>
                  </span>
                </div>

                <div className={"dimmer" + (isLoading ? " active" : "")}>
                  <div className="loader"></div>
                  <div className="dimmer-content">
                    <div
                      className="table-responsive"
                      style={{ minHeight: "150px" }}
                    >
                      {!isLoading && projects.length !== 0 && (
                        <table className="table card-table table-vcenter text-nowrap">
                          <thead>
                            <tr>
                              <th>Título</th>
                              <th>Data de Criação</th>
                              <th>Visibilidade</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {projects.map(item => (
                              <tr key={item.project.id}>
                                <td>{item.project.title}</td>
                                <td>
                                  <Moment format="DD/MM/YYYY HH:mm:ss">
                                    {item.project.createdAt}
                                  </Moment>
                                </td>
                                <td>
                                  <span
                                    className={
                                      "status-icon bg-" +
                                      (item.project.visibility === "PUBLIC"
                                        ? "success"
                                        : "default")
                                    }
                                  />
                                  {item.project.visibility === "PUBLIC"
                                    ? "Público"
                                    : "Privado"}
                                </td>

                                <td className="text-right">
                                  <button
                                    onClick={() =>
                                      integrationModal(item.project)
                                    }
                                    type="button"
                                    className="btn btn-sm btn-secondary mr-2"
                                  >
                                    <i className="fe fe-link mr-2"></i>
                                    Integrar
                                  </button>

                                  <button
                                    onClick={() =>
                                      history.push(
                                        `/project/${item.project.slug}`
                                      )
                                    }
                                    type="button"
                                    className="btn btn-sm btn-secondary"
                                  >
                                    <i className="fe fe-maximize mr-2"></i>
                                    Visualizar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      {!isLoading && projects.length === 0 && (
                        <span
                          className="text-muted"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "64px"
                          }}
                        >
                          Você ainda não criou nenhum projeto.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
