import { takeLatest, put, call } from "redux-saga/effects";
import { registerInAPI } from "../../../services/auth";
import navigateTo from "../../../services/navigation";

import { toastr } from "react-redux-toastr";

function* register(action) {
  yield put({ type: "REGISTER_PENDING" });

  try {
    const authResult = yield call(registerInAPI, action.payload);

    if (authResult.status === 201) {
      yield put({ type: "REGISTER_SUCCESS", payload: authResult.data });
      toastr.success("", "Cadastro efetuado com sucesso!");
      navigateTo("/login");
    } else {
      yield put({ type: "REGISTER_FAILURE" });
      toastr.error("", "Erro ao cadastrar.");
    }
  } catch (error) {
    yield put({ type: "REGISTER_FAILURE" });
    toastr.error("", "Erro ao cadastrar.");
  }
}

export default function* watchRegister() {
  yield takeLatest("REGISTER", register);
}
