import React, { useState, useEffect } from "react";

import Wrapper from "../../components/Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";
import api from "../../providers/api";
import BackButton from "../../components/BackButton";
import { history } from "../../helpers/routing";
import { toastr } from "react-redux-toastr";

export default function CreateProject({ isEditing, match }) {
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [visibility, setVisibility] = useState("PRIVATE");
  const [isLoading, setIsLoading] = useState(isEditing);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState({});
  const [editingProject, setEditingProject] = useState(null);

  useEffect(() => {
    if (isEditing) {
      api
        .get(`/project/${match.params.slug}`)
        .then(response => {
          const project = response.data.data;

          setEditingProject(project);
          setTitle(project.title);
          setVisibility(project.visibility);
          setDescription(project.description);
          setLink(project.slug);
        })
        .catch(err => {
          console.log(err);
          setEditingProject(null);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setEditingProject(null);
    }
  }, [match.params.slug, isEditing]);

  const validateForm = async () => {
    const validationErrors = {};

    validationErrors.titleMissing = !title;
    validationErrors.linkMissing = !link;

    if (link && link !== match.params.slug) {
      const response = await api.post(`/project/validate_slug`, {
        slug: isEditing ? match.params.slug : "",
        newSlug: link
      });

      validationErrors.slugTaken = response.status !== 200;
      setErrors(validationErrors);
      return (
        !validationErrors.titleMissing &&
        !validationErrors.slugTaken &&
        !validationErrors.linkMissing
      );
    } else {
      setErrors(validationErrors);
      return (
        !validationErrors.titleMissing &&
        !validationErrors.linkMissing &&
        !validationErrors.slugTaken
      );
    }
  };

  const handleDelete = async e => {
    e.preventDefault();

    const confirmOptions = {
      onOk: async () => {
        setIsDeleting(true);

        const response = await api.delete(`/project/${match.params.slug}`);
        setIsDeleting(false);

        if (response.status === 200) {
          toastr.success("", "Projeto excluído com sucesso!");
          history.replace("/");
        } else {
          toastr.error("", "Erro ao excluir projeto.");
        }
      },
      onCancel: () => {}
    };

    toastr.confirm(
      "Tem certeza que deseja excluir este projeto?",
      confirmOptions
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (await validateForm()) {
      setIsLoading(true);

      let response = null;

      if (isEditing) {
        response = await api.put(`/project/${match.params.slug}`, {
          title,
          description,
          visibility,
          slug: link
        });
      } else {
        response = await api.post("/project", {
          title,
          description,
          visibility,
          slug: link
        });
      }

      setIsLoading(false);

      if (
        (isEditing && response.status === 200) ||
        (!isEditing && response.status === 201)
      ) {
        toastr.success(
          "",
          `Projeto ${
            response.status === 201 ? "criado" : "alterado"
          } com sucesso!`
        );
        history.replace(`/project/${response.data.data.slug}`);
      } else {
        toastr.error("", "Erro ao salvar projeto.");
      }
    }
  };

  const onBlurLink = async e => {
    let linkUpdated = link;

    while (linkUpdated.endsWith("-")) {
      linkUpdated = linkUpdated.substr(0, linkUpdated.length - 1);
    }

    setLink(linkUpdated.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  };

  const handleChangeLink = e => {
    setLink(
      e.target.value
        .replace(/ /g, "-")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
  };

  const onBlurTitle = e => {
    if (!link) {
      let generatedSlug = e.target.value.toLowerCase().replace(/ /g, "-");

      while (generatedSlug.endsWith("-")) {
        generatedSlug = generatedSlug.substr(0, generatedSlug.length - 1);
      }

      setLink(generatedSlug.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }
  };

  return (
    <Wrapper>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form className="card" onSubmit={handleSubmit}>
                <div className="card-header">
                  <div className="d-flex">
                    <BackButton />
                    <h3 className="card-title">
                      {isEditing
                        ? editingProject && editingProject.title
                        : "Criar projeto"}
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className={"dimmer" + (isLoading ? " active" : "")}>
                    <div className="loader"></div>
                    <div className="dimmer-content">
                      <div className="row">
                        <div className="col-md-5 col-lg-5">
                          <div className="form-group">
                            <label htmlFor="txtTitle" className="form-label">
                              Título
                            </label>
                            <input
                              id="txtTitle"
                              value={title}
                              onBlur={onBlurTitle}
                              onChange={e => setTitle(e.target.value)}
                              type="text"
                              className={
                                "form-control" +
                                (errors.titleMissing ? " is-invalid" : "")
                              }
                              name="example-text-input-invalid"
                              placeholder="Título do projeto"
                            />
                            {errors.titleMissing && (
                              <div className="invalid-feedback">
                                Informe um título válido
                              </div>
                            )}
                            {errors.slugTaken && (
                              <div className="invalid-feedback">
                                Informe um título válido
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                          <div className="form-group">
                            <label htmlFor="txtLink" className="form-label">
                              Link (https://tcc.mvsouza.com.br/project/{link})
                            </label>
                            <input
                              id="txtLink"
                              value={link}
                              onBlur={onBlurLink}
                              onChange={handleChangeLink}
                              type="text"
                              className={
                                "form-control" +
                                (errors.slugTaken || errors.linkMissing
                                  ? " is-invalid"
                                  : "")
                              }
                              name="example-text-input-invalid"
                              placeholder="Link do projeto"
                            />
                            {errors.linkMissing && (
                              <div className="invalid-feedback">
                                Informe o link do projeto
                              </div>
                            )}
                            {errors.slugTaken && (
                              <div className="invalid-feedback">
                                Este link já está sendo utilizado
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <div className="form-group">
                            <label
                              htmlFor="cmbVisibility"
                              className="form-label"
                            >
                              Visibilidade
                            </label>
                            <select
                              id="cmbVisibility"
                              className="form-control custom-select"
                              value={visibility}
                              onChange={e => setVisibility(e.target.value)}
                            >
                              <option value="PRIVATE">Privado</option>
                              <option value="PUBLIC">Público</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="form-group">
                            <label
                              htmlFor="txtDescription"
                              className="form-label"
                            >
                              Descrição
                              <span className="form-label-small">
                                {description.length}
                              </span>
                            </label>
                            <textarea
                              id="txtDescription"
                              className="form-control"
                              name="example-textarea-input"
                              rows="6"
                              placeholder="Descrição do projeto"
                              onChange={e => setDescription(e.target.value)}
                              value={description}
                              style={{ resize: "none" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <div className="d-flex">
                    {isEditing && (
                      <button
                        type="button"
                        className="btn btn-danger ml-auto mr-2"
                        disabled={isLoading || isDeleting}
                        onClick={handleDelete}
                      >
                        {isLoading || isDeleting ? (
                          <FontAwesomeIcon icon={loadingIcon} spin />
                        ) : (
                          "Excluir Projeto"
                        )}
                      </button>
                    )}

                    <button
                      type="submit"
                      className={"btn btn-dark" + (isEditing ? "" : " ml-auto")}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={loadingIcon} spin />
                      ) : isEditing ? (
                        "Salvar Alterações"
                      ) : (
                        "Criar projeto"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
