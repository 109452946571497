import React, { useState, useEffect } from "react";

import Wrapper from "../../components/Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";
import api from "../../providers/api";
import { history } from "../../helpers/routing";
import { toastr } from "react-redux-toastr";

import { getAuthInfo, setAuthInfo } from "../../helpers/auth";
import { existsUsername } from "../../helpers/validation";
import { getGravatarUrl } from "../../helpers/avatar";
import Modal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";

export default function Profile({ isEditing, match }) {
  const [isLoading, setIsLoading] = useState(isEditing);
  const [errors, setErrors] = useState({});
  const [isDeletingAccount, setDeletingAccount] = useState(false);
  const [visible, setVisible] = useState(false);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [confirmacao, setConfirmacao] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [selectedTab, setSelectedTab] = useState("basic");

  const dispatch = useDispatch();
  const authData = useSelector(state => state.auth.authData);

  useEffect(() => {
    if (authData) {
      setUserName(authData.user.name);
      setUserEmail(authData.user.email);

      setName(authData.user.name);
      setEmail(authData.user.email);
      setUsername(authData.user.username);
    }
  }, [authData]);

  const validateForm = async validatingPassword => {
    const validationErrors = {};

    if (validatingPassword) {
      validationErrors.passwordMissing = !password;
      validationErrors.passwordConfirmationMissing = !passwordConfirmation;
      validationErrors.passwordConfirmationInvalid =
        password && passwordConfirmation && password !== passwordConfirmation;

      setErrors(validationErrors);

      return (
        !validationErrors.passwordMissing &&
        !validationErrors.passwordConfirmationMissing &&
        !validationErrors.passwordConfirmationInvalid
      );
    }

    const authInfo = getAuthInfo();

    if (username && authInfo.user.username !== username) {
      const usernameTaken = await existsUsername(username);
      validationErrors.usernameTaken = usernameTaken;
    }

    validationErrors.nomeMissing = !name;
    validationErrors.usernameMissing = !username;
    validationErrors.emailMissing = !email;

    setErrors(validationErrors);

    return (
      !validationErrors.nomeMissing &&
      !validationErrors.usernameMissing &&
      !validationErrors.emailMissing &&
      !validationErrors.usernameTaken
    );
  };

  const handleChangePassword = async e => {
    e.preventDefault();

    if (await validateForm(true)) {
      setIsLoading(true);

      const response = await api.put(`/user`, {
        password
      });

      setIsLoading(false);

      if (response.status === 200) {
        setPassword("");
        setPasswordConfirmation("");
        toastr.success("", "Senha alterada com sucesso!");
      } else {
        setPassword("");
        setPasswordConfirmation("");
        toastr.error("", "Erro ao alterar senha.");
      }
    }
  };

  const handleDeleteAccount = async e => {
    e.preventDefault();

    if (!confirmacao || confirmacao !== "confirmo") {
      setErrors({ confirmacaoWrong: true });
      return;
    }

    setDeletingAccount(true);
    const response = await api.delete(`/user`);
    setDeletingAccount(false);

    if (response.status === 200) {
      toastr.success("", "Sua conta foi removida com sucesso!");
      history.replace("/login");
    } else {
      toastr.error("", "Erro ao remover sua conta.");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (await validateForm(false)) {
      setIsLoading(true);

      const response = await api.put(`/user`, {
        name,
        username
      });

      setIsLoading(false);

      if (response.status === 200) {
        const authInfo = getAuthInfo();

        const newAuthInfo = {
          ...authInfo,
          user: {
            ...authInfo.user,
            name: response.data.data.name,
            username: response.data.data.username
          }
        };

        dispatch({ type: "AUTHENTICATE_SUCCESS", payload: newAuthInfo });

        setAuthInfo(newAuthInfo);
        setUserName(newAuthInfo.user.name);
        setUserEmail(newAuthInfo.user.email);

        toastr.success("", "Alterações salvas com sucesso!");
        history.replace("/profile");
      } else {
        toastr.error("", "Erro ao salvar alterações.");
      }
    }
  };

  return (
    <Wrapper>
      <Modal
        closeOnClickOutside
        visible={visible}
        setVisible={setVisible}
        title="Excluir Minha Conta"
        isLoading={isDeletingAccount}
      >
        <form onSubmit={handleDeleteAccount}>
          <div className="form-group">
            <ul style={{ paddingLeft: "20px" }}>
              <li>Todos os seus dados serão apagados.</li>
              <li>
                Todos os seus projetos serão excluídos e não poderão ser
                recuperados.
              </li>
              <li>
                Você poderá criar uma nova conta com o email que utilizava nesta
                conta.
              </li>
            </ul>

            <input
              id="txtConfirmacao"
              value={confirmacao}
              onChange={e => setConfirmacao(e.target.value)}
              type="text"
              className={
                "form-control" + (errors.confirmacaoWrong ? " is-invalid" : "")
              }
              name="example-text-input-invalid"
              placeholder="Digite 'confirmo' para excluir sua conta"
            />
            {errors.confirmacaoWrong && (
              <div className="invalid-feedback">
                Confirme que deseja excluir sua conta digitando no campo acima a
                palavra <b>confirmo</b>
              </div>
            )}
          </div>

          <div className="text-right">
            <button
              type="submit"
              className="btn btn-danger ml-auto"
              disabled={isDeletingAccount}
            >
              {isDeletingAccount ? (
                <FontAwesomeIcon icon={loadingIcon} spin />
              ) : (
                "Desejo excluir minha conta"
              )}
            </button>
          </div>
        </form>
      </Modal>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card card-profile">
                <div className="card-header"></div>
                <div className="card-body text-center">
                  <img
                    className="card-profile-img"
                    src={getGravatarUrl(userEmail)}
                    alt="Imagem de perfil"
                  />
                  <h3 className="mb-3">{userName}</h3>
                  <p className="mb-4">{userEmail}</p>

                  <button
                    type="button"
                    className="btn btn-danger ml-auto mr-2"
                    disabled={isDeletingAccount}
                    onClick={() => setVisible(true)}
                  >
                    Excluir Minha Conta
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="card" onSubmit={handleSubmit}>
                <div className="card-header">
                  <div className="d-flex">
                    <h3 className="card-title">Perfil</h3>
                  </div>
                </div>

                <div className="menu">
                  <ul>
                    <li
                      onClick={() => setSelectedTab("basic")}
                      className={selectedTab === "basic" ? "menu-selected" : ""}
                    >
                      Informações Básicas
                    </li>
                    <li
                      onClick={() => setSelectedTab("password")}
                      className={
                        selectedTab === "password" ? "menu-selected" : ""
                      }
                    >
                      Alterar Senha
                    </li>
                  </ul>
                </div>

                {selectedTab === "basic" && (
                  <div className="card-body">
                    <div className={"dimmer" + (isLoading ? " active" : "")}>
                      <div className="loader"></div>
                      <div className="dimmer-content">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <div className="form-group">
                              <label htmlFor="txtNome" className="form-label">
                                Nome
                              </label>
                              <input
                                id="txtNome"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.nomeMissing ? " is-invalid" : "")
                                }
                                name="example-text-input-invalid"
                                placeholder="Nome"
                              />
                              {errors.nomeMissing && (
                                <div className="invalid-feedback">
                                  Informe o nome
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <div className="form-group">
                              <label
                                htmlFor="txtNomeUsuario"
                                className="form-label"
                              >
                                Nome de usuário
                              </label>
                              <input
                                id="txtNomeUsuario"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.usernameMissing ||
                                  errors.usernameTaken
                                    ? " is-invalid"
                                    : "")
                                }
                                name="example-text-input-invalid"
                                placeholder="Nome de usuário"
                              />
                              {errors.usernameMissing && (
                                <div className="invalid-feedback">
                                  Informe o nome de usuário
                                </div>
                              )}
                              {errors.usernameTaken && (
                                <div className="invalid-feedback">
                                  Este nome de usuário já está sendo utilizado
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                              <label htmlFor="txtEmail" className="form-label">
                                Email
                              </label>
                              <input
                                id="txtEmail"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.emailInvalid || errors.emailMissing
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Email"
                                onChange={e => {
                                  setEmail(e.target.value);
                                }}
                                value={email}
                                disabled={true}
                              />
                              {errors.emailMissing && (
                                <div className="invalid-feedback">
                                  Informe o endereço de email
                                </div>
                              )}
                              {errors.emailInvalid && (
                                <div className="invalid-feedback">
                                  Digite um endereço de email válido
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedTab === "password" && (
                  <div className="card-body">
                    <div className={"dimmer" + (isLoading ? " active" : "")}>
                      <div className="loader"></div>
                      <div className="dimmer-content">
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                              <label htmlFor="txtSenha" className="form-label">
                                Senha
                              </label>
                              <input
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.passwordMissing ||
                                  errors.passwordConfirmationInvalid
                                    ? " is-invalid"
                                    : "")
                                }
                                id="txtSenha"
                                placeholder="Senha"
                                onChange={e => {
                                  setPassword(e.target.value);
                                }}
                                value={password}
                                disabled={isLoading}
                              />
                              {errors.passwordMissing && (
                                <div className="invalid-feedback">
                                  Informe a senha
                                </div>
                              )}
                              {errors.passwordConfirmationInvalid && (
                                <div className="invalid-feedback">
                                  As senhas não são iguais
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="txtConfirmacaoSenha"
                                className="form-label"
                              >
                                Repita a senha
                              </label>
                              <input
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.passwordConfirmationMissing ||
                                  errors.passwordConfirmationInvalid
                                    ? " is-invalid"
                                    : "")
                                }
                                id="txtConfirmacaoSenha"
                                placeholder="Confirmação de senha"
                                onChange={e => {
                                  setPasswordConfirmation(e.target.value);
                                }}
                                value={passwordConfirmation}
                                disabled={isLoading}
                              />
                              {errors.passwordConfirmationMissing && (
                                <div className="invalid-feedback">
                                  Informe novamente sua senha
                                </div>
                              )}
                              {errors.passwordConfirmationInvalid && (
                                <div className="invalid-feedback">
                                  As senhas não são iguais
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-footer text-right">
                  <div className="d-flex">
                    <button
                      type="button"
                      className={"btn btn-dark" + (isEditing ? "" : " ml-auto")}
                      disabled={isLoading}
                      onClick={
                        selectedTab === "basic"
                          ? handleSubmit
                          : handleChangePassword
                      }
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={loadingIcon} spin />
                      ) : selectedTab === "password" ? (
                        "Alterar Senha"
                      ) : (
                        "Salvar"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
