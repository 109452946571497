import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function CodeBlock({ language, value }) {
  return (
    <SyntaxHighlighter
      language={language}
      style={dracula}
      customStyle={{ padding: "1em" }}
    >
      {value}
    </SyntaxHighlighter>
  );
}
