import React from "react";

import { history } from "../../helpers/routing";

import "./style.css";

export default function BackButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={() => onClick || history.goBack()}
      className="back-button"
    >
      <i className="fe fe-arrow-left"></i>
    </button>
  );
}
