import { takeLatest, put, call } from "redux-saga/effects";
import { deleteProjectFromApi } from "../../../services/projects";

function* deleteProject(action) {
  yield put({ type: "DELETE_PROJECT_PENDING", id: action.id });

  try {
    const { count } = yield call(deleteProjectFromApi, action.id);
    if (count !== 1) throw new Error("API delete request failed");
    yield put({ type: "DELETE_PROJECT_SUCCESS", id: action.id });
  } catch (error) {
    yield put({ type: "DELETE_PROJECT_FAILURE" });
  }
}

export default function* watchDeleteProject() {
  yield takeLatest("DELETE_PROJECT", deleteProject);
}
