import React, { useState } from "react";
import "./styles.css";

export default function DropdownButton({ items, buttonText, buttonIcon }) {
  const [isVisible, setVisible] = useState(false);

  const DEFAULT_ITEMS = [
    {
      label: "Editar",
      icon: "edit",
      onPress: () => {}
    },
    {
      label: "Excluir",
      icon: "trash",
      onPress: () => {}
    }
  ];

  const menuItems = items && items.length > 0 ? items : DEFAULT_ITEMS;

  const beforeOnPress = item => {
    setVisible(false);
    item.onPress();
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setVisible(!isVisible)}
        className="btn btn-icon btn-dark btn-sm"
      >
        {buttonIcon ? (
          <i className={`fe fe-${buttonIcon}`}></i>
        ) : buttonText ? (
          buttonText
        ) : (
          "Gerenciar"
        )}
      </button>
      <div
        style={{ display: isVisible ? "block" : "none" }}
        className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
      >
        {menuItems.map((item, index) => {
          return item.divider ? (
            <div key={index} className="dropdown-divider"></div>
          ) : (
            <button
              key={item.label}
              className="dropdown-item"
              onClick={() => beforeOnPress(item)}
            >
              <i className={`dropdown-icon fe fe-${item.icon}`}></i>{" "}
              {item.label}
            </button>
          );
        })}
      </div>
    </>
  );
}
