import { takeLatest, put, call } from "redux-saga/effects";
import { fetchProjectsFromApi } from "../../../services/projects";

function* fetchProjects() {
  yield put({ type: "FETCH_PROJECTS_PENDING" });

  try {
    const projectsFromAPI = yield call(fetchProjectsFromApi);
    yield put({
      type: "FETCH_PROJECTS_SUCCESS",
      payload: projectsFromAPI.data.data
    });
  } catch (error) {
    yield put({ type: "FETCH_PROJECTS_FAILURE" });
  }
}

export default function* watchFetchProjects() {
  yield takeLatest("FETCH_PROJECTS", fetchProjects);
}
