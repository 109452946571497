import React from "react";

import "./styles.css";

export default function Modal({
  children,
  title,
  isLoading,
  visible,
  setVisible,
  closeOnClickOutside,
  onClose
}) {
  return visible ? (
    <>
      <div
        className="modal-background"
        onClick={() => closeOnClickOutside && setVisible(false)}
      ></div>
      <div className="card modal-custom">
        <div className="card-header">
          <h3 className="card-title">{title || "Modal"}</h3>
          <div className="card-options">
            <button
              onClick={() => {
                if (setVisible) {
                  setVisible(false);

                  if (onClose) {
                    onClose();
                  }
                }
              }}
              className="close-modal-btn card-options-remove mr-3"
            >
              <i className="fe fe-x"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className={"dimmer" + (isLoading ? " active" : "")}>
            <div className="loader"></div>
            <div className="dimmer-content">{children}</div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
