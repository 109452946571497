const INITIAL_STATE = {
  items: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_PROJECTS_PENDING":
    case "DELETE_PROJECT_PENDING":
    case "CREATE_PROJECT_PENDING":
    case "UPDATE_PROJECT_PENDING":
      return {
        ...state,
        loading: true
      };
    case "FETCH_PROJECTS_SUCCESS":
      return {
        items: action.payload,
        loading: false
      };
    case "FETCH_PROJECTS_FAILURE":
      return {
        items: [],
        loading: false
      };
    case "DELETE_PROJECT_SUCCESS": {
      const project_id = action.id;
      return {
        items: state.items.filter(project => project.id !== project_id),
        loading: false
      };
    }
    case "CREATE_PROJECT_SUCCESS":
      return {
        items: [action.payload].concat(state.items),
        loading: false
      };
    case "UPDATE_PROJECT_SUCCESS": {
      const { id, ...rest } = action.payload;

      return {
        items: state.items.map(project => {
          if (project.id === id) {
            return { ...project, ...rest };
          }
          return project;
        }),
        loading: false
      };
    }
    case "CREATE_PROJECT_FAILURE":
    case "DELETE_PROJECT_FAILURE":
    case "UPDATE_PROJECT_FAILURE":
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
