import axios from "axios";

import { getToken, logoutUser } from "../helpers/auth";

const api = axios.create({
 // baseURL: "http://localhost:8081/api",
  baseURL: "https://ci.mvsouza.com.br/api",
  validateStatus: status => {
    return status < 500;
  }
});

api.interceptors.response.use(function(response) {
  if (response.status === 401 || response.status === 403) {
    logoutUser();
  } else {
    return response;
  }
});

api.interceptors.request.use(async config => {
  try {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (err) {
    alert(err);
  }
});

export default api;
