import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import brand from "../../assets/images/tabler.svg";
import { history } from "../../helpers/routing";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const isLoading = useSelector(state => state.auth.loading);

  const dispatch = useDispatch();

  const validateForm = () => {
    const validationErrors = {};

    validationErrors.emailMissing = !email ? true : false;
    validationErrors.passwordMissing = !password ? true : false;

    setErrors(validationErrors);

    return !validationErrors.emailMissing && !validationErrors.passwordMissing;
  };

  const handleLogin = async e => {
    e.preventDefault();

    if (validateForm()) {
      dispatch({ type: "AUTHENTICATE", payload: { email, password } });
    }
  };

  return (
    <div className="page-single">
      <div className="container">
        <div className="row">
          <div className="col col-login mx-auto">
            <div className="text-center mb-6">
              <img src={brand} className="h-6" alt="" />
            </div>
            <form className="card" autoComplete="off" onSubmit={handleLogin}>
              <div className="card-body p-6">
                <div className="card-title">Faça login</div>

                <div className="form-group">
                  <label htmlFor="txtEmail" className="form-label">
                    Email
                  </label>
                  <input
                    id="txtEmail"
                    type="text"
                    className={
                      "form-control" +
                      (errors.emailMissing ? " is-invalid" : "")
                    }
                    placeholder="Email ou nome de usuário"
                    autoFocus
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    disabled={isLoading}
                  />
                  {errors.emailMissing && (
                    <div className="invalid-feedback">
                      Informe um email ou nome de usuário
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="txtSenha" className="form-label">
                    Senha
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errors.passwordMissing ? " is-invalid" : "")
                    }
                    id="txtSenha"
                    placeholder="Senha"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    disabled={isLoading}
                  />
                  {errors.passwordMissing && (
                    <div className="invalid-feedback">Informe a senha</div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                        />
                        <span className="custom-control-label">Lembrar-me</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="custom-link float-right small"
                      onClick={() => history.replace("/forgot_password")}
                    >
                      Esqueci minha senha
                    </button>
                  </div>
                </div>
                <div className="form-footer">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon={loadingIcon} spin />
                    ) : (
                      "Entrar"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-muted">
              Ainda não possui uma conta?{" "}
              <button
                type="button"
                className="custom-link"
                onClick={() => history.replace("/signup")}
              >
                Crie uma conta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
