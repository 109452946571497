import api from "../providers/api";

export function fetchProjectsFromApi() {
  return api.get("/project");
}

export function deleteProjectFromApi(id) {
  return api.delete(`/project/${id}`);
}

export function createtProjectInAPI(payload) {
  return api.post("/project", payload);
}

export function updateProjectInAPI(payload) {
  const { id, ...rest } = payload;

  return api.put(`/project/${id}`, rest);
}
