const INITIAL_STATE = {
  authData: {},
  authenticated: false,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "AUTHENTICATE_PENDING":
    case "REGISTER_PENDING":
      return {
        ...state,
        loading: true
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        loading: false,
        authData: {}
      };
    case "AUTHENTICATE_SUCCESS":
      return {
        ...state,
        authData: action.payload,
        authenticated: true,
        loading: false
      };
    case "AUTHENTICATE_FAILURE":
    case "REGISTER_FAILURE":
      return {
        ...state,
        loading: false,
        authenticated: false,
        authData: {}
      };
    default:
      return state;
  }
};
