import config from "../config/app";

export const get = key => {
  const data = window.localStorage.getItem(`${config.storagePrefix}${key}`);
  return data != null ? JSON.parse(data) : null;
};

export const set = (key, data) => {
  window.localStorage.setItem(
    `${config.storagePrefix}${key}`,
    JSON.stringify(data)
  );
};

export const remove = key => {
  window.localStorage.removeItem(`${config.storagePrefix}${key}`);
};
