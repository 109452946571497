import React from "react";

import Header from "../Header";
import Footer from "../Footer";

export default function Wrapper({ children, ...props }) {
  return (
    <>
      <div className={"page-" + (props.pageContent ? "content" : "main")}>
        {!props.hideHeader && <Header />}
        <div>{children}</div>
      </div>
      {!props.hideFooter && <Footer />}
    </>
  );
}
