import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import Login from "./pages/Login";
import Cadastro from "./pages/Cadastro";
import Projects from "./pages/Projects";
import CreateProject from "./pages/CreateProject";
import ForgotPassword from "./pages/ForgotPassword";
import ProjectDetails from "./pages/ProjectDetails";
import LoadingScreen from "./pages/LoadingScreen";
import NotFoundPage from "./pages/NotFoundPage";
import VersionDetails from "./pages/VersionDetails";
import CreateVersion from "./pages/CreateVersion";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import EmailConfirmation from "./pages/EmailConfirmation";
import Documentation from "./pages/Documentation";

import {
  isAuthenticated,
  validateJWTExistingToken,
  getAuthInfo
} from "./helpers/auth";

import { history } from "./helpers/routing";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} isEditing={rest.isEditing || false} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);
export default function() {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    validateJWTExistingToken().then(res => {
      dispatch({ type: "AUTHENTICATE_SUCCESS", payload: getAuthInfo() });
      setLoaded(true);
    });
  }, [dispatch]);

  return (
    <>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={state => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
        confirmOptions={{
          okText: "Confirmar",
          cancelText: "Cancelar"
        }}
      />
      {loaded ? (
        <Router history={history}>
          <Switch>
            <ProtectedRoute exact path="/" component={Projects} />
            <ProtectedRoute
              path="/project/new"
              exact
              component={CreateProject}
              isEditing={false}
            />
            <ProtectedRoute
              path="/project/:slug/edit"
              component={CreateProject}
              isEditing={true}
            />
            <ProtectedRoute
              path="/project/:slug/publish"
              exact
              component={CreateVersion}
              isEditing={false}
            />
            <ProtectedRoute
              path="/project/:slug/:version/edit"
              component={CreateVersion}
              isEditing={true}
            />
            <ProtectedRoute
              path="/project/:slug/:version"
              component={VersionDetails}
            />
            <ProtectedRoute path="/project/:slug" component={ProjectDetails} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Cadastro} />
            <Route path="/forgot_password" component={ForgotPassword} />
            <Route path="/reset_password/:token" component={ResetPassword} />
            <Route
              path="/email_confirmation/:token"
              component={EmailConfirmation}
            />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/documentation" component={Documentation} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
