import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";

import brand from "../../assets/images/tabler.svg";
import api from "../../providers/api";

import { validateEmail } from "../../helpers/validation";

import { toastr } from "react-redux-toastr";

export default function ForgotPassword({ history }) {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const validationErrors = {};

    validationErrors.emailMissing = !email ? true : false;
    validationErrors.emailInvalid =
      email && !validateEmail(email) ? true : false;

    setErrors(validationErrors);

    return !validationErrors.emailMissing && !validationErrors.emailInvalid;
  };

  const handleLogin = async e => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const response = await api.post("/auth/reset_password", {
        email
      });

      setIsLoading(false);

      if (response.status === 200) {
        toastr.success("", "Email de recuperação foi enviado com sucesso!");
        history.replace("/login");
      } else {
        toastr.error("", "Erro ao enviar email de recuperação de senha.");
      }
    }
  };

  return (
    <div className="page-single">
      <div className="container">
        <div className="row">
          <div className="col col-login mx-auto">
            <div className="text-center mb-6">
              <img src={brand} className="h-6" alt="" />
            </div>
            <form className="card" autoComplete="off" onSubmit={handleLogin}>
              <div className="card-body p-6">
                <div className="card-title">Esqueci minha senha</div>

                <p className="text-muted">
                  Informe seu endereço de email abaixo e enviaremos para você um
                  link de redefinição de senha.
                </p>

                <div className="form-group">
                  <label htmlFor="txtEmail" className="form-label">
                    Email
                  </label>
                  <input
                    id="txtEmail"
                    type="text"
                    className={
                      "form-control" +
                      (errors.emailInvalid || errors.emailMissing
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Email"
                    autoFocus
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    disabled={isLoading}
                  />
                  {errors.emailMissing && (
                    <div className="invalid-feedback">
                      Informe o endereço de email
                    </div>
                  )}
                  {errors.emailInvalid && (
                    <div className="invalid-feedback">
                      Digite um endereço de email válido
                    </div>
                  )}
                </div>

                <div className="form-footer">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon={loadingIcon} spin />
                    ) : (
                      "Recuperar senha"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-muted">
              Lembrou da senha?{" "}
              <button
                className="custom-link"
                onClick={() => history.replace("/login")}
              >
                Faça login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
