import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import projects from "./projects";
import auth from "./auth";

const reducers = combineReducers({
  projects,
  auth,
  toastr: toastrReducer
});

export default reducers;
