import React, { useState, useEffect } from "react";

import Wrapper from "../../components/Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";
import api from "../../providers/api";
import BackButton from "../../components/BackButton";
import { history } from "../../helpers/routing";
import { toastr } from "react-redux-toastr";

import asFormData from "json-form-data";

export default function CreateVersion({ isEditing, match }) {
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(isEditing);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState({});
  const [editingVersion, setEditingVersion] = useState(null);

  useEffect(() => {
    if (isEditing) {
      api
        .get(`/version/${match.params.slug}/${match.params.version}`)
        .then(response => {
          const version = response.data.data;

          setEditingVersion(version);
          setTitle(version.title);
          setDescription(version.changelog);
        })
        .catch(err => {
          console.log(err);
          setEditingVersion(null);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setEditingVersion(null);
    }
  }, [match.params.slug, match.params.version, isEditing]);

  const validateForm = () => {
    const validationErrors = {};

    validationErrors.titleMissing = !title;

    setErrors(validationErrors);

    return !validationErrors.titleMissing;
  };

  const handleDelete = async e => {
    e.preventDefault();

    const confirmOptions = {
      onOk: async () => {
        setIsDeleting(true);

        const response = await api.delete(
          `/version/${match.params.slug}/${match.params.version}`
        );
        setIsDeleting(false);

        if (response.status === 200) {
          toastr.success("", "Versão excluída com sucesso!");
          history.replace(`/project/${match.params.slug}`);
        } else {
          toastr.error("", "Erro ao excluir versão.");
        }
      },
      onCancel: () => {}
    };

    toastr.confirm(
      "Tem certeza que deseja excluir esta versão?",
      confirmOptions
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      let response = null;

      if (isEditing) {
        response = await api.put(
          `/version/${match.params.slug}/${match.params.version}`,
          {
            changelog: description
          }
        );
      } else {
        response = await api.post(
          `/version/${match.params.slug}`,
          asFormData({
            title,
            changelog: description
          })
        );
      }

      setIsLoading(false);

      if (
        (isEditing && response.status === 200) ||
        (!isEditing && response.status === 201)
      ) {
        toastr.success(
          "",
          `Versão ${
            response.status === 201 ? "publicada" : "alterada"
          } com sucesso!`
        );
        history.replace(
          `/project/${match.params.slug}/${response.data.data.title}`
        );
      } else {
        toastr.error("", "Erro ao salvar versão.");
      }
    }
  };

  return (
    <Wrapper>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form className="card" onSubmit={handleSubmit}>
                <div className="card-header">
                  <div className="d-flex">
                    <BackButton />
                    <h3 className="card-title">
                      {isEditing
                        ? editingVersion && editingVersion.title
                        : "Publicar uma versão"}
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  <div className={"dimmer" + (isLoading ? " active" : "")}>
                    <div className="loader"></div>
                    <div className="dimmer-content">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="form-group">
                            <label htmlFor="txtTitle" className="form-label">
                              Título
                            </label>
                            <input
                              id="txtTitle"
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              type="text"
                              className={
                                "form-control" +
                                (errors.titleMissing ? " is-invalid" : "")
                              }
                              name="example-text-input-invalid"
                              placeholder="Título da versão"
                              disabled={isEditing}
                            />
                            {errors.titleMissing && (
                              <div className="invalid-feedback">
                                Informe um título válido
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="form-group">
                            <label
                              htmlFor="txtDescription"
                              className="form-label"
                            >
                              Descrição
                              <span className="form-label-small">
                                {description.length}
                              </span>
                            </label>
                            <textarea
                              id="txtDescription"
                              className="form-control"
                              name="example-textarea-input"
                              rows="6"
                              placeholder="Descrição do projeto"
                              onChange={e => setDescription(e.target.value)}
                              value={description}
                              style={{ resize: "none" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <div className="d-flex">
                    {isEditing && (
                      <button
                        type="button"
                        className="btn btn-danger ml-auto mr-2"
                        disabled={isLoading || isDeleting}
                        onClick={handleDelete}
                      >
                        {isLoading || isDeleting ? (
                          <FontAwesomeIcon icon={loadingIcon} spin />
                        ) : (
                          "Excluir Versão"
                        )}
                      </button>
                    )}

                    <button
                      type="submit"
                      className={"btn btn-dark" + (isEditing ? "" : " ml-auto")}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={loadingIcon} spin />
                      ) : isEditing ? (
                        "Salvar Alterações"
                      ) : (
                        "Publicar Versão"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
