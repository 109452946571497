import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../../components/CodeBlock";

import "./styles.css";
import doc from "./docs.md";

export default function Documentation() {
  const [docText, setDocText] = useState("");

  useEffect(() => {
    fetch(doc)
      .then(response => response.text())
      .then(text => setDocText(text));
  }, []);

  return (
    <Wrapper>
      <div className="my-3 my-md-5">
        <div className="container">
          <div className="row row-cards row-deck">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Documentação</h3>
                </div>
                <ReactMarkdown
                  renderers={{ code: CodeBlock }}
                  className="doc"
                  source={docText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
