import { takeLatest, put, call } from "redux-saga/effects";
import { createtProjectInAPI } from "../../../services/projects";
import navigateTo from "../../../services/navigation";

function* createProject(action) {
  yield put({ type: "CREATE_PROJECT_PENDING" });

  try {
    const newProject = yield call(createtProjectInAPI, action.payload);
    yield put({ type: "CREATE_PROJECT_SUCCESS", payload: newProject.data });
    navigateTo("/");
  } catch (error) {
    yield put({ type: "CREATE_PROJECT_FAILURE" });
  }
}

export default function* watchCreateProject() {
  yield takeLatest("CREATE_PROJECT", createProject);
}
