import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";

import brand from "../../assets/images/tabler.svg";
import {
  validateEmail,
  validatePasswordStrength,
  existsEmail,
  existsUsername
} from "../../helpers/validation";
import { history } from "../../helpers/routing";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordStrengthValidation, setPasswordStrengthValidation] = useState(
    ""
  );

  const isLoading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();

  const validateForm = async () => {
    const validationErrors = {};

    validationErrors.emailMissing = !email;
    validationErrors.usernameMissing = !username;
    validationErrors.passwordMissing = !password;
    validationErrors.passwordInvalid = false;
    validationErrors.passwordConfirmationMissing = !passwordConfirmation;
    validationErrors.passwordConfirmationInvalid =
      password && passwordConfirmation && password !== passwordConfirmation;

    if (password && passwordConfirmation && password === passwordConfirmation) {
      const passwordStrength = validatePasswordStrength(password);
      setPasswordStrengthValidation(
        passwordStrength.valid ? "" : passwordStrength.errors[0]
      );
      validationErrors.passwordInvalid = !passwordStrength.valid;
    } else {
      setPasswordStrengthValidation("");
    }

    validationErrors.emailInvalid =
      email && !validateEmail(email) ? true : false;

    if (!validationErrors.emailMissing && !validationErrors.emailInvalid) {
      const emailTaken = await existsEmail(email);
      validationErrors.emailTaken = emailTaken;
    }

    if (username) {
      const usernameTaken = await existsUsername(username);
      validationErrors.usernameTaken = usernameTaken;
    }

    validationErrors.nameMissing = !name;

    setErrors(validationErrors);

    return (
      !validationErrors.emailMissing &&
      !validationErrors.usernameMissing &&
      !validationErrors.emailInvalid &&
      !validationErrors.emailTaken &&
      !validationErrors.usernameTaken &&
      !validationErrors.passwordMissing &&
      !validationErrors.passwordConfirmationMissing &&
      !validationErrors.nameMissing &&
      !validationErrors.passwordConfirmationInvalid &&
      !validationErrors.passwordInvalid
    );
  };

  const handleLogin = async e => {
    e.preventDefault();

    if (await validateForm()) {
      dispatch({
        type: "REGISTER",
        payload: { name, email, password, username }
      });
    }
  };

  return (
    <div className="page-single">
      <div className="container">
        <div className="row">
          <div className="col col-login mx-auto">
            <div className="text-center mb-6">
              <img src={brand} className="h-6" alt="" />
            </div>
            <form className="card" autoComplete="off" onSubmit={handleLogin}>
              <div className="card-body p-6">
                <div className="card-title">Crie uma conta</div>

                <div className="form-group">
                  <label htmlFor="txtName" className="form-label">
                    Nome
                  </label>
                  <input
                    id="txtName"
                    type="text"
                    className={
                      "form-control" + (errors.nameMissing ? " is-invalid" : "")
                    }
                    placeholder="Nome"
                    autoFocus
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    value={name}
                    disabled={isLoading}
                  />
                  {errors.nameMissing && (
                    <div className="invalid-feedback">Informe o seu nome</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="txtUserName" className="form-label">
                    Nome de Usuário
                  </label>
                  <input
                    id="txtUserName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.usernameMissing || errors.usernameTaken ? " is-invalid" : "")
                    }
                    placeholder="Nome de Usuário"
                    onChange={e => {
                      setUsername(e.target.value);
                    }}
                    value={username}
                    disabled={isLoading}
                  />
                  {errors.usernameMissing && (
                    <div className="invalid-feedback">
                      Informe o seu nome de usuário
                    </div>
                  )}

                  {errors.usernameTaken && (
                    <div className="invalid-feedback">
                      Este nome de usuário já está sendo utilizado
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="txtEmail" className="form-label">
                    Email
                  </label>
                  <input
                    id="txtEmail"
                    type="text"
                    className={
                      "form-control" +
                      (errors.emailInvalid || errors.emailMissing || errors.emailTaken
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Email"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    disabled={isLoading}
                  />
                  {errors.emailMissing && (
                    <div className="invalid-feedback">
                      Informe o endereço de email
                    </div>
                  )}
                  {errors.emailInvalid && (
                    <div className="invalid-feedback">
                      Digite um endereço de email válido
                    </div>
                  )}
                  {errors.emailTaken && (
                    <div className="invalid-feedback">
                      Este email já está sendo utilizado
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="txtSenha" className="form-label">
                    Senha
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errors.passwordMissing ||
                      errors.passwordConfirmationInvalid ||
                      errors.passwordInvalid
                        ? " is-invalid"
                        : "")
                    }
                    id="txtSenha"
                    placeholder="Senha"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    disabled={isLoading}
                  />
                  {errors.passwordMissing && (
                    <div className="invalid-feedback">Informe a senha</div>
                  )}
                  {errors.passwordConfirmationInvalid && (
                    <div className="invalid-feedback">
                      As senhas não são iguais
                    </div>
                  )}
                  {passwordStrengthValidation && (
                    <div className="invalid-feedback">
                      {passwordStrengthValidation}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="txtConfirmacaoSenha" className="form-label">
                    Repita a senha
                  </label>
                  <input
                    type="password"
                    className={
                      "form-control" +
                      (errors.passwordConfirmationMissing ||
                      errors.passwordConfirmationInvalid ||
                      errors.passwordInvalid
                        ? " is-invalid"
                        : "")
                    }
                    id="txtConfirmacaoSenha"
                    placeholder="Confirmação de senha"
                    onChange={e => {
                      setPasswordConfirmation(e.target.value);
                    }}
                    value={passwordConfirmation}
                    disabled={isLoading}
                  />
                  {errors.passwordConfirmationMissing && (
                    <div className="invalid-feedback">
                      Informe novamente sua senha
                    </div>
                  )}
                  {errors.passwordConfirmationInvalid && (
                    <div className="invalid-feedback">
                      As senhas não são iguais
                    </div>
                  )}
                  {passwordStrengthValidation && (
                    <div className="invalid-feedback">
                      {passwordStrengthValidation}
                    </div>
                  )}
                </div>

                <div className="form-footer">
                  <button type="submit" className="btn btn-primary btn-block">
                    {isLoading ? (
                      <FontAwesomeIcon icon={loadingIcon} spin />
                    ) : (
                      "Criar conta"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-muted">
              Já possui uma conta?{" "}
              <button
                type="button"
                className="custom-link"
                onClick={() => history.replace("/login")}
              >
                Faça login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
