import React from "react";

import Wrapper from "../../components/Wrapper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch as loadingIcon } from "@fortawesome/free-solid-svg-icons";

export default function LoadingScreen() {
  return (
    <Wrapper hideHeader hideFooter pageContent>
      <div className="container text-center">
        <FontAwesomeIcon
          icon={loadingIcon}
          style={{ width: "75px", height: "75px" }}
          spin
        />
      </div>
    </Wrapper>
  );
}
